import { createApp, nextTick } from 'vue';
import './css/compass.css';
import { logEvent } from './helpers/logging';

import { useAuthenticateStore } from './store/pinia/authenticate';
import { useApplicationSettingsStore } from './store/pinia/applicationSettings';

import { createPinia } from 'pinia';
const pinia = createPinia();

import App from './App.vue';
import router from './router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
const toastOptions = {
  position: 'bottom-left',
  draggable: false,
  timeout: 8000,
};

import * as Sentry from '@sentry/vue';

import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, fa } from 'vuetify/iconsets/fa';
import { mdi } from 'vuetify/iconsets/mdi';
import * as vuetifyComponents from 'vuetify/components';
import * as vuetifyDirectives from 'vuetify/directives';
import { ApolloClient, concat, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { getToken } from './services/authenticate';


const shouldOutputDebuggingConsoleLogs = true;
const app = createApp(App);
app.use(pinia);

const applicationSettingsStore = useApplicationSettingsStore();
applicationSettingsStore.getApplicationSettings()
  .then(() => {

const vuetify = createVuetify({
  ssr: false,
  components: {
    vuetifyComponents,
  },
  directives: {
    vuetifyDirectives,
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
});

router.afterEach((to) => {
  nextTick(() => {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  });
});

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: applicationSettingsStore.appUrls.appBaseGraphQLHost,
});

const authHeader = setContext(async _ => {
  const token = await getToken();
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authHeader, httpLink),
  cache: new InMemoryCache(),
});

app.provide(DefaultApolloClient, apolloClient);

app.use(Toast, toastOptions);
app.use(vuetify);
app.use(router);

// This is only called when the Pinia store is initialized upon loading the app
// We don't want to create the compassAttributes localStorage object unless
// it doesn't already exist
// Otherwise, we will erase the localStorage values that may already be set and needed
const authenticateStore = useAuthenticateStore();
try {
  const storedData = localStorage.getItem('compassAttributes');
  if (storedData) {
    // Parse the existing object from localStorage
    const storedObject = JSON.parse(storedData);
    authenticateStore.setLocalStorageObject(storedObject);
  } else {
    localStorage.setItem('compassAttributes', JSON.stringify(authenticateStore.localStorageObject));
  }
} catch (error) {
  logEvent({
    type: 'error',
    message: `Error parsing data from localStorage: ${error.message}`,
    functionName: '',
    fileName: 'main.ts',
  });
  localStorage.setItem('compassAttributes', JSON.stringify(authenticateStore.localStorageObject));
}

// Initialize MSAL Instance
try {
  authenticateStore.initializeMsalInstance();
} catch (error) {
  logEvent({
    type: 'error',
    message: `Error initialize msal Instance: ${error.message}`,
    functionName: '',
    fileName: 'main.ts',
  });
}

// ####################################### Sentry Initialization ####################################### //
// Setting up Sentry
if (applicationSettingsStore.sentryConfiguration.enabled && applicationSettingsStore.sentryConfiguration.enabled !== 'false') {
  Sentry.init({
    app,
    dsn: applicationSettingsStore.sentryConfiguration.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: applicationSettingsStore.sentryConfiguration.sampleRate,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // We have to set environment here because Sentry uses it over any tab we could set
    // eslint-disable-next-line
    beforeSend: (event, hint) => {
      event.environment = applicationSettingsStore.nodeEnv;
      return event;
    },
  });
} else {
  if (shouldOutputDebuggingConsoleLogs) {
    logEvent({
      type: 'verbose',
      message: 'Sentry disabled',
      functionName: '',
      fileName: 'main.ts',
    });
  }
}

app.mount('#app');
logEvent({
  type: 'verbose',
  message: 'App Mounted',
  functionName: '',
  fileName: 'main.ts',
  });
});
