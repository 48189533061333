const routes = [
  {
    path: '/providerrelations',
    name: 'providerrelations',
    redirect: { name: 'provider-relations-dashboard' },
    components: {
      content: () => import('../views/ProviderRelations.vue'),
    },
    meta: {
      title: 'Provider Relations',
      PageH1: 'Provider Relations',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
    children: [
      {
        path: '/providerrelations/dashboard',
        name: 'provider-relations-dashboard',
        components: {
          providerRelationsContent: () => import('@components/partials/providerrelations/ProviderRelationsCardDashboard.vue'),
        },
        meta: {
          title: 'Provider Relations',
          pageH1: 'Provider Relations - Scoreboard',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
        },
      }],
  },
];

export default routes;
